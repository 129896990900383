import * as React from "react";
import {Link} from "gatsby";

const Section = () => (
    <section className="text-dark body-font">
        <div className="flex relative z-20 items-center">
            <div className="container mx-auto px-6 py-8 flex flex-col justify-between items-center relative">
                <div className="flex flex-col my-2">
                    <h2 className="max-w-3xl text-5xl md:text-6xl font-bold text-center py-2 my-4">
                        Page not found
                    </h2>
                    <div className="flex flex-col items-center justify-start mt-6 sm:mt-8">
                        <div className="uppercase mb-8">
                            Sorry{" "}
                            <span role="img" aria-label="Pensive emoji"/>
                            {" "}we couldn’t find what you were looking for.
                        </div>
                        <div className="uppercase font-black text-3xl flex items-center">
                            <Link to="/"
                                  className="underline decoration-accent decoration-2 decoration-wavy hover:decoration-secondary">Go
                                home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Section;
