import * as React from "react"
import Header from "../components/landing/header"
import Seo from "../components/seo"
import NotFoundSection from "../components/404.section"


const Page = () => (
    <>
        <Seo title={'Not found'}/>
        <main className="font-mono relative overflow-hidden h-screen">
            <Header/>
            <NotFoundSection/>
        </main>
    </>
)

export default Page
